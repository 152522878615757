import { Swiper, Navigation, Pagination, Autoplay, EffectFade } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

export default () => {
    var swiper = new Swiper('.gallery-modal-slider', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });

    var swiper = new Swiper('.group-slide', {
      speed: 2000,
      fadeEffect: {
        crossFade: true,
      },
      effect: 'fade',
      autoplay: {
        delay: 2300,
      },
    });

    var currentSlide = 0;
    var studentsSlide = new Swiper('.project-students__slide', {
      slidesPerView: 1,
      watchOverflow: true,
      simulateTouch: false,
      breakpoints: {
        1200: {
          simulateTouch: false,
        },
      },
      on:{
        init: function(){
          let yearStudents = document.querySelectorAll('.project-students__pagination .project-students__pagination-bullet');
          if (yearStudents){
            yearStudents[0].classList.add('is-active');
            currentSlide = yearStudents[0];
            yearStudents.forEach((item,index) => {
              item.addEventListener('click', (e) => {
                if (currentSlide){
                  currentSlide.classList.remove('is-active'); 
                }
                this.slideTo(index);
                item.classList.add('is-active');
                currentSlide = item;
              });
            });
          }
        }
      }
    });

    var studentsThumbs = new Swiper('.students-thumbs', {
      slidesPerView: 6,
      spaceBetween: 20,
      loop: true,
      simulateTouch: false,
      breakpoints: {
        1200: {
          slidesPerView: 2,
          simulateTouch: true,
          centeredSlides: true,
          spaceBetween: 20,
        },
      },
      navigation: {
        nextEl: '.students-thumbs__navigation-next',
        prevEl: '.students-thumbs__navigation-prev',
      },
    });
};
