export default function masks() {
    $("#nascimento").mask("00/00/0000");
    $("#rg").mask("0000000000000");
    $("#cep").mask("00000-000");
    $("#tel-fixo").mask("(00) 0000-0000");
    $("#tel-celular").mask("(00) 00000-0000");
    $("#rg-resposavel").mask("0000000000000");

    var telResponsavelBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },

    telOptions = {
        onKeyPress: function(val, e, field, options) {
            field.mask(telResponsavelBehavior.apply({}, arguments), options);
        }
    };
      
    $('#tel-resposavel').mask(telResponsavelBehavior, telOptions);
}